import i18n from '@/i18n'
export const userType = {
    supervisor: 0,
    medicalRep: 1,
    admin: 2,
}
export const userTypes = [
    { id: 0, name: i18n.t('Staff.administrative') },
    { id: 1, name: i18n.t('Staff.medical-rep') },
]

export const gender = {
    male: 0,
    female: 1
};
export const genders = [
    { id: 0, name: i18n.t('gender.male') },
    { id: 1, name: i18n.t('gender.female') }
];

export const workloads = [
    { id: 0, name: 'A+' },
    { id: 1, name: 'A' },
    { id: 2, name: 'A-' },
    { id: 3, name: 'B+' },
    { id: 4, name: 'B' },
    { id: 5, name: 'B-' },
    { id: 6, name: 'C' },
];

export const contactInfoTypes = [
    { id: 0, name: 'Phone' },
    { id: 1, name: 'Landline' },
    { id: 2, name: 'Email' },
    { id: 3, name: 'Facebook' },
    { id: 4, name: 'Twitter' },
    { id: 5, name: 'WhatsApp' },
    { id: 6, name: 'Telegram' },
    { id: 7, name: 'LinkedIn' },
    { id: 8, name: 'Website' },
];
export const contactInfoType = {
    phone: 0,
    landline: 1,
    email: 2,
    facebook: 3,
    twitter: 4,
    whatsapp: 5,
    telegram: 6,
    linkedin: 7,
    website: 8,
};

export const entityType = {
    center: 0,
    doctor: 1,
    pharmacy: 2,
    external: 3,
}

export const requestTypes = [
    { id: 0, name: 'Administrative Day' },
    { id: 1, name: 'Half Administrative Day' },
    { id: 2, name: 'Sick Leave' },
    { id: 3, name: 'Unpaid Leave' },
    { id: 4, name: ' Administrative Leave' },
]

export const requestStates = [
    { id: 0, name: 'Pending' },
    { id: 1, name: 'Accepted' },
    { id: 2, name: 'Rejected' },
]

export const requestStatus = {
    pending: 0,
    accepted: 1,
    rejected: 2,
}

export const modificationRequestTypes = {
    creation: 0,
    modification: 1,
    deletion: 2,
}

export const permissions = { 
    Leaves: 0,
    AddLeavesForStaff: 1,
    ManageLeaveBalances: 2,
    GenerateMaterials: 3,
    MaterialTransactions: 4,
    MedicalPlans: 5,
    MedicalVisits: 6,
    WeeklyReports: 7,
    MonthlyPlanReports: 8,
    Reviews: 9,
    Exams: 10,
    Salaries: 11,
    MedicalReps: 12,
    Entities: 13,
    EntityModificationRequests: 14,
    Charts: 15,
    Regions: 16,
    Users: 17,
    Roles: 18,
    GeneralFilter: 19,
    ManageTeam: 20
}

export const permissionsWithName = [
    {
       permissionName: 'Leaves',
       id: 0 
    },
    {
        permissionName: 'Add Leaves For Staff',
        id: 1
    },
    {
        permissionName: 'Manage Leave Balances',
        id: 2
    },
    {
        permissionName: 'Generate Materials',
        id: 3
    },
    {
        permissionName: 'Material Transactions',
        id: 4
    },
    {
        permissionName: 'Medical Plans',
        id: 5
    },
    {
        permissionName: 'Medical Visits',
        id: 6
    },
    {
        permissionName: 'Weekly Reports',
        id: 7
    },
    {
        permissionName: 'Monthly Plan Reports',
        id: 8
    },
    {
        permissionName: 'Reviews',
        id: 9
    },
    {
        permissionName: 'Exams',
        id: 10
    },
    {
        permissionName: 'Salaries',
        id: 11
    },
    {
        permissionName: 'MedicalReps',
        id: 12
    },
    {
        permissionName: 'Entities',
        id: 13
    },
    {
        permissionName: 'Entity Modification Requests',
        id: 14
    },
    {
        permissionName: 'Charts',
        id: 15
    },
    {
        permissionName: 'Regions',
        id: 16
    },
    {
        permissionName: 'Users',
        id: 17
    },
    {
        permissionName: 'Roles',
        id: 18
    },
    {
        permissionName: 'GeneralFilter',
        id: 19
    },
    {
        permissionName: 'ManageTeam',
        id: 20
    }
]
export const roleName = {
    nothing: 0,
    read : 1,
    action: 2
}

export const transactionState = [
    { name: 'إتلاف', id: 0 }, 
    { name: 'توزيع مجاني', id: 1 }, 
    { name: 'مرتجع', id: 2 },
    { name: 'تسوية الجرد', id: 3 }
]

export const entityTypeArray = [
    {
        id:0,
        name: i18n.t('entity-type.center')
    },
    {
        id: 1,
        name: 'طبيب'
    },
    {
        id: 2,
        name: 'صيدلية'
    },
    {
        id: 3,
        name: 'جهة خارجية'
    }
];
