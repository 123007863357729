<template>
    <div>        
        <!-- modify Details dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="550"
            ref="modifyDetailsDialog"
        >
            <template v-slot:default="dialog">
                <v-form @submit.prevent="submit" ref="form">
                    <v-card>
                        <v-card-title color="primary" dark flat class="text-uppercase text-body-1 justify-center primary white--text py-0">
                            <v-spacer/>
                            {{$t('ModificationRequests.modify-details')}}
                            <v-spacer/>

                            <v-btn icon color="white" @click="dialog.value = false">
                                <v-icon> mdi-close-circle-outline </v-icon>
                            </v-btn>
                        </v-card-title>
                        <v-card-text class="pt-2 pb-0 px-4">
                            <v-row class="ma-0" dense>
                                <!-- name -->
                                <v-col sm="6" cols="12">
                                    <v-text-field
                                        :label="$t('inputs.name')"
                                        readonly
                                        hide-details
                                        dense
                                        outlined
                                        :value="modifyDialogData.name"
                                    ></v-text-field>
                                </v-col>

                                <!-- specialty -->
                                <v-col v-if="tab === 0" sm="6" cols="12">
                                    <v-autocomplete
                                        :label="$t('inputs.specialty')"
                                        :value="modifyDialogData.specialtyId"
                                        :items="specialties"
                                        item-text="name"
                                        item-value="id"
                                        append-icon=""
                                        readonly
                                        hide-details
                                        dense
                                        outlined
                                    ></v-autocomplete>
                                </v-col>
                            </v-row>

                            <!-- contacts infos -->
                            <div v-if="modifyDialogData.contactInfos.length > 0" class="mb-2">
                                <p class="mb-2"> {{ $t('contact-information') }} :</p>
                                <bee-handy-table
                                    :headers="contactInfoHeaders"
                                    :items="modifyDialogData.contactInfos"
                                    dense
                                    hide-shown-rows-text
                                    :items-per-page="-1"
                                    hide-default-footer
                                ></bee-handy-table>
                            </div>

                            <!-- addresses -->
                            <div v-if="modifyDialogData.addresses.length > 0" class="mb-2">
                                <p class="mb-2">{{ $t('ModificationRequests.addresses') }}:</p>
                                <bee-handy-table
                                    :headers="addressesHeaders"
                                    :items="modifyDialogData.addresses"
                                    dense
                                    hide-shown-rows-text
                                    :items-per-page="-1"
                                    hide-default-footer
                                ></bee-handy-table>
                            </div>

                            <!-- reason -->
                            <div class="grey--text text--darken-2">
                                <p class="mb-0">{{ $t('ModificationRequests.modification-reason') }}:</p>
                                {{modifyDialogData.reason}}
                            </div>
                        </v-card-text>
                        <v-card-actions class="justify-end pt-1">
                            <v-btn elevation="1" @click="dialog.value = false">
                                {{ $t('cancel') }}
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </template>
        </v-dialog>

        <!-- request dialog -->
        <v-dialog
            transition="dialog-transition"
            max-width="450"
            ref="requestDialog"
        >
            <template v-slot:default="dialog">
                <v-card>
                    <div 
                        class="d-flex py-1"  
                        :class="(isAcceptance ? 'success' : 'red lighten-2') + ' white--text'"
                    >
                        <v-card-title class="text-uppercase text-subtitle-1 flex-grow-1 justify-center pa-0">
                            {{ isAcceptance ? $t('ModificationRequests.confirm-acceptance') : $t('ModificationRequests.confirm-rejection') }}
                        </v-card-title>

                        <v-btn icon color="white" @click="dialog.value = false">
                            <v-icon> mdi-close-circle-outline </v-icon>
                        </v-btn>
                    </div>
                    <v-card-text class="pt-1 pb-0">
                    
                        <span class="text-body-1">
                            {{ $t('are-you-sure-you-want-to') }} 
                            <span :class="isAcceptance ? 'success--text' : 'red--text text--lighten-1'">
                                {{isAcceptance? $t('ModificationRequests.accept') : $t('ModificationRequests.reject') }}
                            </span>
                            {{ $t('ModificationRequests.this-request') + $t('?') }} 
                        </span>
                    </v-card-text>
                    <v-card-actions class="justify-end pt-1">
                        <v-btn
                            text
                            :loading="submitLoading"
                            :color="isAcceptance ? 'success' : 'red lighten-1'"
                            class="white--text text-body-2"
                            @click="submitConfirmingRequest"
                        >
                            {{ $t('confirm') }}
                        </v-btn>
                        <v-btn
                            @click="dialog.value = false"
                            :disabled="submitLoading"
                            text
                            class="text-body-2"
                        >
                            {{ $t('close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </template>
        </v-dialog>

        <!-- tabs -->
        <v-tabs v-model="tab" @change="needToResetItems = true; clearFilter(); filter();">
            <v-tab  v-for="title in tabs.titles" :key="title" class="text-capitalize">
                {{ title }}
            </v-tab>
            <v-tabs-slider color="success"></v-tabs-slider>
        </v-tabs>
        <v-divider/>

        <!-- filter -->
        <v-form @submit.prevent="isFiltered = true; needToResetItems = true; filter();" :disabled="loading">
            <v-row dense class="align-center my-1">
                <!-- city region filter -->
                <v-col lg="auto" md="4" sm="6" cols="12">
                    <v-row dense>
                        <!-- cities -->
                        <v-col cols="5">
                            <v-autocomplete
                                v-model="cityId"
                                :items="cities"
                                item-text="name"
                                item-value="id"
                                :label="$t('inputs.city')"
                                dense
        
                                hide-details
                                append-icon
                                :disabled="loading"
                                outlined
                                @change="fetchRegionsDueCity"
                            ></v-autocomplete>
                        </v-col>
            
                        <!-- region -->
                        <v-col cols="7">
                            <v-autocomplete
                                v-model="regionIds"
                                :items="regions"
                                item-text="name"
                                item-value="id"
                                :label="$t('inputs.region')"
                                dense
                                multiple
                                clearable
                                hide-details
                                :loading="regionsLoading"
                                :disabled="loading || regionsLoading || cityId === null"
                                outlined
                                @click:clear="cityId = null"
                            >
                                <template v-slot:selection="{ item, index }">
                                    <span v-if="index === 0" class="me-1">{{ item.name }}</span>
                                    <span v-if="index === 1" class="grey--text text-caption">
                                        (+{{ regionIds.length - 1 }})
                                    </span>
                                </template>

                                <template v-slot:prepend-item>
                                    <v-list-item ripple @click="toggleRegion">
                                        <v-list-item-action>
                                            <v-icon color='indigo darken-4'>{{ iconRegions }}</v-icon>
                                        </v-list-item-action>
                                        <v-list-item-content>
                                            <v-list-item-title>{{ $t('select-all') }}</v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                    <v-divider />
                                </template>
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                </v-col>
            
                <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical class="my-2"/>
                
                <!-- start date -->
                <v-col lg="2" md="3" sm="6" cols="12">
                    <bee-date-picker
                        v-model="startDate"
                        :input-label="$t('inputs.start-date')"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true
                        }"
                        :disabled="loading"
                    ></bee-date-picker>
                </v-col>
            
                <!-- end date -->
                <v-col lg="2" md="3" sm="6" cols="12"> 
                    <bee-date-picker
                        v-model="endDate"
                        :input-label="$t('inputs.end-date')"
                        elevation="2"
                        menu-picker
                        dense
                        hide-details
                        :input-props="{
                            outlined: true,
                            dense: true
                        }"
                        :disabled="loading"
                    ></bee-date-picker>
                </v-col>
            
                <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical class="my-2"/>
            
                <!-- name of -->
                <v-col md="2" sm="6" cols="12">
                    <v-text-field
                        v-model="name"
                        item-text="name"
                        item-value="name"
                        :label="$t('inputs.name')"
                        dense
                        hide-details
                        clearable
                        :disabled="loading"
                        outlined                    
                    ></v-text-field>
                </v-col>
            
                <!-- specialty -->
                <v-col v-if="tab === 0" md="2" sm="6" cols="12">
                    <v-autocomplete
                        v-model="specialtyId"
                        :items="specialties"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.specialty')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
            
                <!-- workload -->
                <v-col v-if="tab === 0" md="2" sm="6" cols="12">
                    <v-autocomplete
                        v-model="workload"
                        :items="classification"
                        item-text="name"
                        item-value="id"
                        :label="$t('inputs.class')"
                        dense
                        clearable
                        hide-details
                        :disabled="loading"
                        outlined
                    ></v-autocomplete>
                </v-col>
            
                <v-divider v-if="$vuetify.breakpoint.smAndUp" vertical class="my-2"/>
            
                <!-- request types & buttons -->
                <v-col cols="auto" class="flex-grow-1">
                    <div class="d-flex flex-wrap justify-space-between align-center">
                        <!-- Requests type -->
                        <v-radio-group row v-model="requestState" hide-details class="mt-0 pt-0">
                            <span class="text-body-1 ms-sm-1 me-3">{{$t('ModificationRequests.request-type')}}:</span>
                            <v-radio :label="$t('inputs.pending')" :value="0"></v-radio>
                            <v-radio :label="$t('inputs.accepted')" :value="1"></v-radio>
                            <v-radio :label="$t('inputs.rejected')" :value="2"></v-radio>
                        </v-radio-group>
            
                        <div>
                            <v-btn
                                color="success"
                                class="px-sm-4 me-2"
                                :loading="loading"
                                :disabled="isAllFieldsEmpty || loading"
                                type="submit"
                                small
                                elevation="0"
                            >
                                <v-icon> mdi-magnify </v-icon>
                                {{ $t('search') }}
                            </v-btn>
                            <v-btn
                                color="alert-color white--text"
                                class="px-sm-8"
                                :disabled="!isFiltered || loading"
                                @click="needToResetItems = true; clearFilter(); filter();"
                                small
                                elevation="0"
                            >
                                {{ $t('reset') }}
                            </v-btn>

                            <v-divider class="mx-2" vertical/>
                            <!-- BACK -->
                            <v-btn
                                color="grey darken-2 white--text"
                                small
                                :to="{name: 'customers'}"
                            >
                                <v-icon small class="me-1"> mdi-arrow-left-circle </v-icon>
                                {{ $t('back') }}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </v-form>

        <!-- data table -->
        <bee-handy-table
            ref="table"
            :headers="headers"
            :items="[]"
            :loading="loading"
            :data-table-props="{ disableSort: true }"
            dense
            zebra
            fixed-header
            :height="$vuetify.breakpoint.height - 230"
            :server-items-length="totalRequests"
            pagination-on-scroll
            pagination-on-scroll-server-items
            :options-scroll.sync="scrollOptions"
            @pagination-on-scroll:reach-last-row="filter"
            :shown-rows-text="$t('shown-rows')"
        >
            <!-- action column -->
            <template v-slot:item.actions="{item}">
                <div class="d-flex">
                    <tooltip top :text="$t('ModificationRequests.view-modify-details')">
                        <v-btn color="primary" text small @click="activeModifyDialog(item)">
                            <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip top :text="$t('ModificationRequests.accept-request')">
                        <v-btn text small color="success" @click="confirmRequest(item ,item.id, true)">
                            <v-icon>mdi-check</v-icon>
                        </v-btn>
                    </tooltip>
                    
                    <tooltip top :text="$t('ModificationRequests.reject-request')">
                        <v-btn text small @click="confirmRequest(item, item.id, false)">
                            <v-icon color="red lighten-2">mdi-close</v-icon>
                        </v-btn>
                    </tooltip>
                </div>
            </template>
        </bee-handy-table>
    </div>
</template>

<script>
import Tooltip from '@/components/Tooltip'
import { mapState, mapGetters } from 'vuex'
import { dig, findClassification } from '@/helpers/functions'
import { contactInfoTypes, entityType, modificationRequestTypes, roleName } from '@/helpers/enums'
export default {
    name: 'ModificationRequests',

    components:{
        Tooltip
    },

    data(){
        return {     
            roleName,
            // Filter data
            selectedRequestState: 0,
            name: null,
            workload: null,
            specialtyId: null,
            cityId: null,
            regionIds: [],
            requestState: 0,
            startDate: null,
            endDate: null,
            
            // helpers
            loading: false,
            submitLoading: false,
            regionsLoading: false,
            isFiltered: false,
            tab: 0,
            tabs: {
                titles:[this.$t('tabs.doctors'), this.$t('tabs.pharmacies'), this.$t('tabs.centers')],
                labels: [this.$t('tabs.doctor'), this.$t('tabs.pharmacies'), this.$t('tabs.centers')],
            },

            // pagination props
            needToResetItems: false,
            scrollOptions: {},
            totalRequests: null,
            
            // request dialog
            isAcceptance: true,

            // modification details dialog
            contactInfoHeaders: [
                {
                    text: this.$t('headers.contact-type'),
                    name: 'contactInfoType',
                    value: 'contactInfoType',
                    setValueExpr: (val) => contactInfoTypes.find(c => c.id === val).name,
                    noNotAvailableText: true
                },
                {
                    text: this.$t('headers.contact'),
                    name: 'contactValue',
                    value: 'value',
                }
            ],

            addressesHeaders: [
                {
                    text: this.$t('headers.city'),
                    name: 'city',
                    value: 'regionId',
                    setValueExpr: val => {
                        if (val.length === 0) return '-';
                        const cityId = this.dig('getRegionAllById', val, 'cityId');
                        const cityName = this.dig('getCityById', cityId, 'name');
                        return cityName;
                    },
                },
                {
                    text: this.$t('headers.region'),
                    name: 'region',
                    value: 'regionId',
                    setValueExpr: (val) => this.dig('getRegionAllById', val, 'name') || '-',
                },
                // {
                //     text: this.$t('headers.class'),
                //     name: 'workload',
                //     value: 'workload',
                //     setValueExpr: (val) => this.getClassificationById(val)?.name,
                //     noNotAvailableText: true
                // },
                {
                    text: this.$t('headers.address'),
                    name: 'address',
                    value: 'address',
                },
            ],
            
            // data
            modificationRequestId: null,
            modifyDialogData: {
                name: null,
                specialtyId: null,
                contactInfos: [],
                addresses: [],
                reason: null,
            },
            itemRow: null
        }
    },

    computed:{
        isAllFieldsEmpty(){
            return (
                !this.name && !this.workload && !this.specialtyId && this.regionIds.length === 0 && this.requestState === null && !this.startDate && !this.endDate
            )
        },

        ...mapState({
            specialties: state => state.specialties.specialties,
            cities: state => state.cities.cities,
            regions: state => state.regions.regions,
            medicalreps: state => state.medicalreps.medicalreps,
            classification: state => state.workload.classification,
            permissionsUser: state => state.auth.youPermissions,
            isSuperAdmin: state => state.auth.isSuperAdmin
        }),

        ...mapGetters({
            getSpecialtyById: 'specialties/getSpecialtyById',
            getRegionById: 'regions/getRegionById',
            getRegionAllById: 'regions/getRegionAllById',
            getEntityById: 'entities/getEntityById',
            getCityById: 'cities/getCityById',
            getMedicalRepById: 'medicalreps/getMedicalRepById',
            getClassificationById: 'workload/getClassificationById',
        }),

        headers() {
            const headers = [
                {
                    text: this.$t('headers.customer-name'), 
                    align: 'start', 
                    value: 'entity.name',
                    width: 140
                },
                { 
                    text: this.$t('headers.classification'),
                    value: 'entity',
                    setValueExpr: val => val.entityAddresses.length ? this.getClassificationById(val.entityAddresses[0].workloadId)?.name : '-',
                    align: 'center',
                },
                { 
                    text: this.$t('headers.specialty'), 
                    value: 'entity.specialtyIds', 
                    setValueExpr: val => this.getSpecialtyById(val[0])?.name,
                    align: 'center', 
                },
                { 
                    text: this.$t('inputs.city'), 
                    value: 'entity.entityAddresses', 
                    setValueExpr: val => {
                        if (val.length === 0) return '-';
                        const cityId = this.dig('getRegionAllById', val[0].regionId, 'cityId');
                        const cityName = this.dig('getCityById', cityId, 'name');
                        return cityName;
                    },
                    align: 'center',
                    noNotAvailableText: true,
                },
                { 
                    text: this.$t('headers.region'), 
                    value: 'entity.entityAddresses', 
                    setValueExpr: val => val.length > 0 ? this.dig('getRegionAllById', val[0].regionId, 'name') : '-',
                    align: 'center', 
                    noNotAvailableText: true,
                },
                { 
                    text: this.$t('headers.telephone-number'), 
                    value: 'entity.contactInfos', 
                    setValueExpr: val => {
                        if (val.length === 0) return '-';
                        const telephone = val.filter(c => c.contactInfoType === 1);
                        return telephone.length === 0 ? '-' : telephone[0].value;
                    },
                    align: 'center', 
                    noNotAvailableText: true,
                },
                { 
                    text: this.$t('headers.phone-number'), 
                    value: 'entity.contactInfos',
                    setValueExpr: val => {
                        if (val.length === 0) return '-';
                        const phone = val.filter(c => c.contactInfoType === 0)
                        return phone.length === 0 ? null : phone[0].value;
                    }, 
                    align: 'center',
                    noNotAvailableText: true,
                },
                { 
                    text: this.$t('ModificationRequests.request-reason'), 
                    value: 'reason', 
                    align: 'center',
                    width: 200,
                },
                { 
                    text: this.$t('ModificationRequests.requested-by'), 
                    value: 'employeeId', 
                    setValueExpr: val => this.dig('getMedicalRepById', val, 'name') || '-',
                    align: 'center',
                },
                { 
                    text: this.$t('ModificationRequests.request-type'), 
                    value: 'requestType',
                    setValueExpr: val => this.findRequestType(val),
                    align: 'center', 
                    noNotAvailableText: true,
                },
            ];

            if (this.tab !== 0) headers.splice(2, 1);
            if (
                (this.selectedRequestState === 0 && this.isSuperAdmin) || 
                (this.selectedRequestState === 0 && this.permissionsUser.EntityModificationRequests === roleName.action)
            ) {
                headers.push({ text: this.$t('headers.actions'), name: 'actions', value: 'actions', align: 'center' });
            }

            const sharedClass = 'text-body-2 font-weight-bold px-2';
            for (let i = 0; i < headers.length; i++) {
                headers[i].class = headers[i].class ? headers[i].class + ' ' + sharedClass : sharedClass;
                headers[i].cellClass = headers[i].cellClass ? headers[i].cellClass + ' px-2' : 'px-2';
            }

            return headers;
        },

        entityTypeForCurrentTab(){
            switch (this.tab) {
                case 0: return entityType.doctor;
                case 1: return entityType.pharmacy;
                case 2: return entityType.center;
                default: return null;
            }
        },

        selectedAllRegions () {
            return this.regionIds.length === this.regions.length;
        },

        iconRegions() {
            if (this.selectedAllRegions) return 'mdi-close-box'
            return 'mdi-checkbox-blank-outline'
        },
    },

    methods:{
        // filter functions
        clearFilter(){
            this.name = null;
            this.workload = null;
            this.specialtyId = null;
            this.cityId = null;
            this.regionIds = [];
            this.requestState = 0;
            this.startDate = null;
            this.endDate = null;

            // clear filter options
            this.totalRequests = 0;
            this.selectedRequestState = 0;
            this.isFiltered = false;
        },

        filter(newScrollOptions, isFirstLoad){
            this.loading = true;
            let page = 1;
            const { itemsPerPage } = this.scrollOptions;

            if (newScrollOptions) {
                page = this.newScrollOptions.page;
            }

            this.selectedRequestState = this.requestState;
            // fetch data depends on filter
            return this.$store.dispatch('modificationRequests/fetchAll', {
                requestStatus: this.requestState,
                type: this.entityTypeForCurrentTab,
                name: this.name,
                workload: this.workload,
                specialtyId: this.specialtyId,
                regionIds: this.regionIds,
                fromDate: this.startDate,
                toDate: this.endDate,
                perPage: itemsPerPage,
                page: page
            })
            .then(({ data, total }) => {
                if (this.needToResetItems) {
                    this.$refs.table.resetShownRows(data);
                    this.needToResetItems = false;
                }
                this.$refs.table.addScrollItems(data);

                this.totalRequests = total;
            })
            .finally(() => {
                if (!isFirstLoad) this.loading = false;
            })
        },

        // modify dialog
        activeModifyDialog(item){
            this.modifyDialogData.name = null;
            this.modifyDialogData.specialtyId = null;
            this.modifyDialogData.contactInfos = [];
            this.modifyDialogData.addresses = [];

            this.modifyDialogData.name = item.entity.name;
            this.modifyDialogData.specialtyId = item.entity.specialtyId;
            this.modifyDialogData.contactInfos = item.entity.contactInfos;
            this.modifyDialogData.addresses = item.entity.entityAddresses;
            this.modifyDialogData.reason = item.reason;
            this.$refs.modifyDetailsDialog.isActive = true;
        },

        // actions functions
        confirmRequest(item, id, isAcceptance) {
            this.modificationRequestId = id;
            this.isAcceptance = isAcceptance;
            this.$refs.requestDialog.isActive = true;
            this.itemRow = item
        },

        submitConfirmingRequest(){
            this.submitLoading = true;
            const actionName = this.isAcceptance ? 'accept' : 'reject';

            this.$store.dispatch(`modificationRequests/${actionName}`, { id: this.modificationRequestId })
            .then(() => {
                if (actionName === 'accept') {
                    const data = {
                       name: this.itemRow.entity.name,
                        entityType: this.itemRow.entity.entityType,
                        specialtyId: this.itemRow.entity.specialtyIds ? this.itemRow.entity.specialtyIds : [],
                        bestTimeToVisit: this.itemRow.entity.entityAddresses.length ? this.itemRow.entity.entityAddresses[0].bestTimeToVisit ? this.itemRow.entity.entityAddresses[0].bestTimeToVisit : null : '-',
                        // openingTime: this.itemRow.openingTime,
                        // closingTime: this.itemRow.closingTime,
                        residentDoctorNames: this.itemRow.residentDoctorNames ? this.itemRow.residentDoctorNames : null,
                        contactInfo: this.itemRow.entity.contactInfos ? this.itemRow.entity.contactInfos : [],
                        addresses: this.itemRow.entity.entityAddresses?.length ? this.itemRow.entity.entityAddresses : []
                    }
                    this.$store.dispatch('entities/create', { data })
                }
                // this.$store.dispatch('')
                const message = 'The Request is ' + (this.isAcceptance ? 'accepted' : 'reject');
                this.$eventBus.$emit('show-snackbar', message, 'info');
                this.$refs.requestDialog.isActive = false;
                this.needToResetItems = true;
                this.filter();
            })
            .finally(() => {
                this.submitLoading = false;
            })
        },

        // Find data functions
        dig,

        fetchRegionsDueCity(){
            this.regionIds = [];
            if (!this.cityId) return;
            
            this.regionsLoading = true;
            this.$store.dispatch('regions/fetchAll', {
                cityId: this.cityId
            })
            .finally(() => {
                this.regionsLoading = false
            })
        },

        findClassification(addresses){
            if (addresses.length === 0) return null;
            const workload = addresses[0].workload;
            return findClassification(workload);
        },

        findRequestType(requestTypeId){
            switch (requestTypeId) {
                case modificationRequestTypes.creation: return 'Creation';
                case modificationRequestTypes.modification: return 'Modification';
                case modificationRequestTypes.deletion: return 'Deletion';
            }
        },

        toggleRegion() {
            this.$nextTick(() => {
                if (this.selectedAllRegions) {
                    this.regionIds = []
                } 
                else {
                    this.regionIds = this.regions.map(c => c.id);
                    // this.employeeData.regionsId.forEach(region => {
                    // if (this.employeeData.regionsId.includes(region.id)) {
                    //     this.employeeData.regionsId.push(region.id);
                    // }
                // });  
                }
            })
        },
    },

    created(){
        this.loading = true;
        Promise.all([
            this.$store.dispatch('workload/fetchAll'),
            !this.specialties.length ? this.$store.dispatch('specialties/fetchAll') : null,
            !this.cities.length
                ? this.$store.dispatch('cities/fetchCityMine').then((data) => {
                    this.$store.dispatch('regions/fetchAllRegions', { cities: data });
                })
                : null,
            this.filter(null, true),
            !this.medicalreps.length ? this.$store.dispatch('medicalreps/fetchAll', false) : null,
        ])
        .finally(() => {
            this.loading = false;
        })
    },

    metaInfo: {
        title: 'Modification Requests',
        titleTemplate: '%s | Octopharma'
    }
}
</script>

{ 
    "id": "7a3e417e-3219-46e4-7d18-08dcafda2432", 
    "requestType": 0, 
    "status": 0, 
    "reason": "addition", 
    "date": "2024-07-29T14:25:07.9101363", 
    "createdAt": "2024-07-29T14:25:08.4411148", 
    "updatedAt": null, 
    "deletedAt": null, 
    "employeeId": "59649135-2c94-4728-427e-08dc8ab695e2", 
    "entity": { 
        "id": "7a3e417e-3219-46e4-7d18-08dcafda2432", 
        "createdAt": "2024-07-29T14:25:08.4411148",
        "updatedAt": null, 
        "deletedAt": null, 
        "isDeleted": false,
        "name": "test adding doctor", 
        "entityType": 1, 
        "specialtyIds": null, 
        "entityAddresses": [
            { 
                "entityId": "00000000-0000-0000-0000-000000000000", 
                "regionId": "38f39fbf-9937-4022-3249-08dc7efedc08", 
                "workloadId": "6f4bee23-06da-4b72-d6cd-08dc7f085587", 
                "visitTime": null, 
                "bestTimeToVisit": null, 
                "address": "heaven", 
                "id": "00000000-0000-0000-0000-000000000000", 
                "createdAt": "0001-01-01T00:00:00", 
                "updatedAt": null 
            } 
        ], 
        "contactInfos": [ 
            { "entityId": "00000000-0000-0000-0000-000000000000", "contactInfoType": 0, "value": "852032145", "id": "00000000-0000-0000-0000-000000000000", "createdAt": "0001-01-01T00:00:00", "updatedAt": null } 
        ], "visitsCount": null, 
        "visitEmployeeIds": null, 
        "bestTimeToVisit": null, 
        "openingTime": null, 
        "closingTime": null, 
        "residentDoctorNames": null 
        } 
    } 